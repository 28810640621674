<template>
<div>
    <!-- Modal -->
    <div class="modal fade" ref="bs" id="modalAdd" tabindex="-1" aria-labelledby="modalAdd" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content modal-conten--white">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <img :src="require('../assets/closeModal.svg')" />
                </button>
                <div class="modal-body" style="position:relative">
                    <div class="row">
                        <div class="colm-md-12">
                            <h2>Добавить блюдо</h2>
                        </div>
                        <div class="col-md-12">
                              <FoodFilter />
                        </div>
                        <div class="col-md-12">
                            <div class="food-category-items mb-4 pb-md-3 overflow-hidden">
                                <FoodCategory :triger='triger' />
                            </div>
                        </div>
                    </div>
                    <div class="row gxFoods">
                        <Loader v-if="loader" />
                        <template v-for="good in goods.products">
                            <FoodItem :good=good :key="good.id" />
                        </template>
                    </div>
                </div>
                <div class="modal-footer mt-4 mb-5 pb-2"></div>
                <!-- <button @click="addToCart(14)">ADD</button> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import FoodCategory from '@/components/FoodCategory.vue'
//import { createLogger } from 'vuex';
//import dateFormat from 'date-format.js';
import FoodFilter from '@/components/FoodFilter.vue'
//import 'moment/dist/locale/ru';
import FoodItem from '@/components/FoodItem.vue'
//import axios from "axios";
import {
    mapState
} from 'vuex'
export default {
    components: {
        FoodCategory,
        FoodItem,
        FoodFilter
    },
    data() {
        return {
            //hoursList: [],
            triger: 1,
        };
    },
    mounted() {
        const th = this;
        this.$refs.bs.addEventListener('shown.bs.modal', function () {
            th.triger++;
        })

    },
    computed: {
        ...mapState([
            'goods', 'loader'
        ])
    },
    watch: {

    },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
.modal-body {
    padding: 40px 31px 0 32px;
}

@media (min-width: 200px) {
    .modal-dialog {
        max-width: 1300px;
        padding-top: 60px;
    }
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.btn-close {
    position: absolute;
    background: none;
    position: absolute;
    top: -50px !important;
    margin: auto !important;
    width: 10px;
    left: 0 !important;
    right: 0 !important;
    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.modal-content {
    background: #f5f5f5;
}
.modal-conten--white{
    background: #fff;
}
</style>
